import React from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@lifeomic/chroma-react/styles";
import { createTheme } from "@lifeomic/chroma-react/styles";
import blue from "@lifeomic/chroma-react/colors/blue";
import darkGraphite from "@lifeomic/chroma-react/colors/darkGraphite";
import { LayoutManager } from "@lifeomic/chroma-react/components/LayoutManager";
import {
  PrimaryNavigationExpansionItem,
  PrimaryNavigation,
  PrimaryNavigationItem,
  PrimaryNavigationSubItem,
} from "@lifeomic/chroma-react/components/PrimaryNavigation";
import { Header } from "@lifeomic/chroma-react/components/Header";
import { Text } from "@lifeomic/chroma-react/components/Text";
import CalculateIcon from "@mui/icons-material/Calculate";
import DocIcon from "@mui/icons-material/Article";
import About from "./About";
import Calculator from "./Calculators/v2.2/Calculator";
import Help from "./Calculators/v2.2/Help";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: blue,
    secondary: darkGraphite,
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LayoutManager
        header={
          <Header
            logo={<CalculateIcon />}
            centerLogo={false}
            left={<Text>Abuse Case Impact Calculator</Text>}
          />
        }
        isSidebarCollapseDisabled={false}
        sidebar={
          <PrimaryNavigation>
            <PrimaryNavigationItem
              icon={<DocIcon />}
              label="About"
              to={"/"}
              exact="true"
            />
            <PrimaryNavigationExpansionItem
              icon={<CalculateIcon />}
              label="v2.2"
              to="/v2.2"
              rootParentPath="/"
              exact="true"
            >
              <PrimaryNavigationSubItem
                label="Calculator"
                to="/v2.2/calculator"
                exact="true"
              />
              <PrimaryNavigationSubItem
                label="Help"
                to="/v2.2/help"
                exact="true"
              />
            </PrimaryNavigationExpansionItem>
          </PrimaryNavigation>
        }
      >
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/v2.2/calculator/*" element={<Calculator />} />
          <Route path="/v2.2/help" element={<Help />} />
          <Route path="*" element={<About />} />
        </Routes>
      </LayoutManager>
    </ThemeProvider>
  );
};

export default App;
