import React, { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@lifeomic/chroma-react/components/Box";
import { Text } from "@lifeomic/chroma-react/components/Text";
import ScoreItem from "../../ScoreItem";
import {
  getCVSSTextScore,
  getNumericScore,
  getVectorString,
  parseVectorString,
} from "./v2.2";
import { PageLayout } from "@lifeomic/chroma-react/components/PageLayout";

const textColors = {
  Undefined: "common.white",
  None: "common.white",
  Low: "green.200",
  Medium: "yellow.200",
  High: "orange.200",
  Critical: "red.200",
};

const Calculator: FC = () => {
  const location = useLocation();
  const vectorParam = location.pathname.substring(17);
  const parsed = parseVectorString(vectorParam as string);
  const [ac, setAC] = useState(parsed.ac);
  const [ae, setAE] = useState(parsed.ae);
  const [vc, setVC] = useState(parsed.vc);
  const [vs, setVS] = useState(parsed.vs);
  const [bi, setBI] = useState(parsed.bi);
  const vector = getVectorString(ac, ae, vc, vs, bi);

  useEffect(() => {
    window.location.href = `#/v2.2/calculator/${vector}`;
  }, [vector]);

  const score = getNumericScore(ac, ae, vc, vs, bi);
  const textScore = getCVSSTextScore(score);
  return (
    <PageLayout title="Abuse Case Impact Calculator v2.2">
      <Box direction="row" padding={2}>
        <Text>Score: </Text>
        <Box bgColor={textColors[textScore]} marginLeft={2}>
          {score !== -1 && (
            <Text>
              {score} {textScore}
            </Text>
          )}
          {score === -1 && (
            <Text>Please make a selection for each category.</Text>
          )}
        </Box>
      </Box>
      <Box direction="row" padding={2}>
        <Text>Vector: {vector}</Text>
      </Box>
      <br />

      <Box fullWidth direction="row" padding={1}>
        <Box width={41}></Box>
        <Box width={70}>
          <Box fullWidth align="center" justify="center">
            <Text align="center">1</Text>
          </Box>
          <Box fullWidth align="center" justify="center">
            <Text align="center">2</Text>
          </Box>
          <Box fullWidth align="center" justify="center">
            <Text align="center">3</Text>
          </Box>
          <Box fullWidth align="center" justify="center">
            <Text align="center">4</Text>
          </Box>
          <Box fullWidth align="center" justify="center">
            <Text align="center">5</Text>
          </Box>
        </Box>
      </Box>

      <ScoreItem
        name="Abuser Controls (AC)"
        toolTip="How much control does the abuser have over the content of the abuse?"
        value={ac}
        set={setAC}
        scale={["Little", "Some", "More", "Extra", "Extensive"]}
      />
      <ScoreItem
        name="Abuser Effort (AE)"
        toolTip="How much effort does the abuser need to expend to invoke the abuse?"
        value={ae}
        set={setAE}
        scale={["Extensive", "Extra", "More", "Some", "Little"]}
      />
      <ScoreItem
        name="Victim Controls (VC)"
        toolTip="How easy is it for victims to substantially stop the abuse once it's been initiated?"
        value={vc}
        set={setVC}
        scale={["Trivial", "Easy", "Somewhat", "Difficult", "Impossible"]}
      />
      <ScoreItem
        name="Victim Surface (VS)"
        toolTip="How many possible victims could the abuser affect?"
        value={vs}
        set={setVS}
        scale={["None/Self", "Few", "Some", "Most", "All"]}
      />
      <ScoreItem
        name="Business Impact (BI)"
        toolTip="How is the business impacted by the abuse?"
        value={bi}
        set={setBI}
        scale={["Indirect", "Low", "Medium", "High", "Severe"]}
      />
    </PageLayout>
  );
};

export default Calculator;
