import React, { FC } from "react";
import { PageLayout } from "@lifeomic/chroma-react/components/PageLayout";
import { Text } from "@lifeomic/chroma-react/components/Text";

const About: FC<{}> = () => (
  <PageLayout title="Abuse Case Impact Calculator">
    <Text marginBottom>
      System abuse occurs when one set of users infringes on another set of
      users' ability to use and enjoy a system, with an impact on the
      business. Platform abuse occurs when one set of users abuses the
      system to the detriment of the business, but not to other users.
    </Text>
    <Text marginBottom>
      This calculator aims to provide an objective measure of system abuse by
      users against other users. This calculator does not attempt to quantify
      platform abuse, which by definition does not affect other users.
    </Text>
    <Text marginBottom>
      To quantify abuse, we weigh the actions of one set of users (the "abusers")
      against the mitigating controls another set of users (the "victims") has
      available, while scaling for the number of potential victims and the
      anticipated business impact.
    </Text>
    <Text marginBottom>
      There are five questions. The first two, AC and AE, gauge how much abuse
      an abuser can generate within the context of our system. The third and
      fourth qustions, VC and VS, gauge how the abused can respond to abuse
      within the controls of the system. These four questions represent the
      essence of the struggle. The final question, BI, gauges the business
      impact of the struggle beween these two groups.
    </Text>
    <Text marginBottom>
      The final score is mapped to the CVSS rubric to provide guidance for
      payouts and SLA. Note that likelihood is not a factor in this calculation.
      That's because business leaders should review all vulnerabilities and
      abuse cases, their corresponding calculated SLA, and make risk assumption
      decisions.
    </Text>
  </PageLayout>
);

export default About;
