import React, { FC } from "react";
import { PageLayout } from "@lifeomic/chroma-react/components/PageLayout";
import { Text } from "@lifeomic/chroma-react/components/Text";

const Help: FC = () => (
  <PageLayout title="Help">
    <Text marginBottom>
      Version 2.2 of the Abuse Case Impact Calculator uses five categories to
      calculate a CVSS like score to be used in cases of user abuse to generate
      an objective score that can be used as guidance for payouts and SLAs.
    </Text>

    <hr />

    <Text size="headline">Categories</Text>

    <Text weight="bold">Abuser Controls (AC):</Text>
    <Text>
      How much control does the abuser have over the content of the abuse?
    </Text>
    <Text weight="bold">Abuser Effort (AE):</Text>
    <Text>
      How much effort does the abuser need to expend to invoke the abuse?
    </Text>
    <Text weight="bold">Victim Controls (VC):</Text>
    <Text>
      How easy is it for victims to substantially stop the abuse once it's been
      initiated?
    </Text>
    <Text weight="bold">Victim Surface (VS):</Text>
    <Text>How many possible victims could the abuser affect?</Text>
    <Text weight="bold">Business Impact (BI):</Text>
    <Text>How is the business impacted by the abuse?</Text>

    <hr />
  </PageLayout>
);

export default Help;
