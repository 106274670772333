const avg = (a: number, b: number) => (a + b) / 2;

export const version = "v2.2";

export const getNumericScore = (
  ac: number,
  ae: number,
  vc: number,
  vs: number,
  bi: number
): number => {
  // Catch the state where the user has not selected something for all options.
  // Return -1 to represent we don't have enough information to calculate a score.
  if (ac === 0 || ae === 0 || vc === 0 || vs === 0 || bi === 0) {
    return -1;
  }
  // v2.2 => IF(VS=1,0,IF(VC=1,1,(AC*0.15+AVERAGE(AE,VS)*0.2+VC*0.15+BI*0.5)*2))
  return vs === 1
    ? 0
    : vc === 1
    ? 1
    : parseFloat(
        ((ac * 0.15 + avg(ae, vs) * 0.2 + vc * 0.15 + bi * 0.5) * 2).toFixed(2)
      );
};

export const getCVSSTextScore = (score: number) => {
  if (score < 0) {
    return "Undefined";
  } else if (score < 0.1) {
    return "None";
  } else if (score < 4.0) {
    return "Low";
  } else if (score < 7.0) {
    return "Medium";
  } else if (score < 9.0) {
    return "High";
  }
  return "Critical";
};

export const getVectorString = (
  ac: number,
  ae: number,
  vc: number,
  vs: number,
  bi: number
) => {
  const score = getNumericScore(ac, ae, vc, vs, bi);
  const textScore = getCVSSTextScore(score);
  return `ACIC:${version}:${textScore
    .substring(0, 3)
    .toUpperCase()}:${score}:AC=${ac}/AE=${ae}/VC=${vc}/VS=${vs}/BI=${bi}`;
};

const vectorRegex =
  /ACIC:v2\.2:[A-Z]{3}:-?\d{1,2}\.?\d{0,2}:AC=(?<ac>[0-5])\/AE=(?<ae>[0-5])\/VC=(?<vc>[0-5])\/VS=(?<vs>[0-5])\/BI=(?<bi>[0-5])/;

type ParsedVectorString = {
  ac: number;
  ae: number;
  vc: number;
  vs: number;
  bi: number;
};

export const parseVectorString = (vector: string): ParsedVectorString => {
  try {
    const match = vectorRegex.exec(vector);
    return {
      ac: parseFloat(match.groups.ac),
      ae: parseFloat(match.groups.ae),
      vc: parseFloat(match.groups.vc),
      vs: parseFloat(match.groups.vs),
      bi: parseFloat(match.groups.bi),
    };
  } catch (e) {
    console.error(`Could not parse vector string "${vector}".`);
    return {
      ac: 0,
      ae: 0,
      vc: 0,
      vs: 0,
      bi: 0,
    };
  }
};
