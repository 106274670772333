import React, { FC } from "react";
import { Box } from "@lifeomic/chroma-react/components/Box";
import { Text } from "@lifeomic/chroma-react/components/Text";
import { Button } from "@lifeomic/chroma-react/components/Button";

interface ScoreItemProps {
  value: number;
  set: (v: number) => void;
  toolTip: string;
  scale?: string[];
  name: string;
}

const ScoreItem: FC<ScoreItemProps> = ({
  value,
  set,
  toolTip,
  scale,
  name,
}) => (
  <Box fullWidth direction="row" padding={1}>
    <Box width={40} paddingRight={1} direction="column">
      <Box direction="row" fullWidth>
        <Text>{name}</Text>
      </Box>
      <Box direction="row" fullWidth>
        <Text weight="light" size="caption">
          {toolTip}
        </Text>
      </Box>
    </Box>
    <Box width={70} height={8} flexChildren>
      <Button
        fullWidth
        style={{
          maxHeight: "100%",
          marginLeft: "0.05rem",
          marginRight: "0.05rem",
        }}
        variant={value === 1 ? "contained" : "outlined"}
        onClick={() => set(1)}
      >
        {scale ? scale[0] : 1}
      </Button>
      <Button
        fullWidth
        style={{
          maxHeight: "100%",
          marginLeft: "0.05rem",
          marginRight: "0.05rem",
        }}
        variant={value === 2 ? "contained" : "outlined"}
        onClick={() => set(2)}
      >
        {scale ? scale[1] : 2}
      </Button>
      <Button
        fullWidth
        style={{
          maxHeight: "100%",
          marginLeft: "0.05rem",
          marginRight: "0.05rem",
        }}
        variant={value === 3 ? "contained" : "outlined"}
        onClick={() => set(3)}
      >
        {scale ? scale[2] : 3}
      </Button>
      <Button
        fullWidth
        style={{
          maxHeight: "100%",
          marginLeft: "0.05rem",
          marginRight: "0.05rem",
        }}
        variant={value === 4 ? "contained" : "outlined"}
        onClick={() => set(4)}
      >
        {scale ? scale[3] : 4}
      </Button>
      <Button
        fullWidth
        style={{
          maxHeight: "100%",
          marginLeft: "0.05rem",
          marginRight: "0.05rem",
        }}
        variant={value === 5 ? "contained" : "outlined"}
        onClick={() => set(5)}
      >
        {scale ? scale[4] : 5}
      </Button>
    </Box>
  </Box>
);

export default ScoreItem;
